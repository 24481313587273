import Vue from 'vue'
import Main from './components/Main.vue'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import vuetify from './plugins/vuetify'
import rules from '@/rules'
import utils from '@/utils'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.config.productionTip = false

Vue.use(VueTheMask)

Vue.prototype.$axios = require('axios').create({
  baseURL: process.env.VUE_APP_API || "http://localhost:8000",
  // baseURL: "https://api.v2.datacertify.com.br",
  withCredentials: true,
})
Vue.prototype.$baseURL = process.env.VUE_APP_API || "http://localhost:8000"
Vue.prototype.$rules = rules
Vue.prototype.$utils = utils

new Vue({
  router,
  store,
  vuetify,
  render: h => h(Main)
}).$mount('#app')
