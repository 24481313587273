<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="primary"
      dark
      v-resize="updateMenuSize"
      ref="appBar"
    >
      <div class="d-flex align-center justify-center" :to="{name: 'home'}">
        <v-avatar>
          <v-img src="@/assets/logo.jpeg" contain />
        </v-avatar>
        <span class="headline font-weight-bold ml-3">
          DataCertify
        </span>
      </div>
    </v-app-bar> -->

    <!-- <v-navigation-drawer v-model="drawer" fixed temporary right>
      <v-list nav dense>
        <v-list-item>
          <v-list-item-content class="text-subtitle-1 font-weight-medium">
            DataCertify
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(menuItem, i) in menuItems" :key="i"
            :exact="menuItem.exact != undefined ? menuItem.exact:true"
            :to="menuItem.link"
          >
            <v-list-item-title>
              {{ menuItem.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-navigation-drawer> -->

    <v-main class="grey lighten-3">
      <v-fade-transition leave-absolute>
        <router-view :contentHeight="contentHeight" />
      </v-fade-transition>
    </v-main>

    <v-snackbar
      v-model="messageSnackbar.showing"
      :color="messageSnackbar.color"
      :timeout="3000"
    >
      <span class="font-weight-medium">
        {{ messageSnackbar.message }}
      </span>
      <template #action>
        <v-btn text @click="messageSnackbar.showing = false">
          ok
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="messageDialog.showing"
      max-width="500px"
    >
      <v-alert
        class="pl-6 mb-0"
        border="left" colored-border
        prominent
        :type="messageDialog.type"
      >
        <div class="d-flex align-center">
          <div class="flex-grow-1 pl-1 pr-2 text-body-1">
            {{ messageDialog.message }}
          </div>
          <v-btn depressed color="grey lighten-3" @click="messageDialog.showing = false">
            ok
          </v-btn>
        </div>
      </v-alert>
    </v-dialog>

  </v-app>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'Main',

  data: () => ({
    messageSnackbar: {
      showing: false,
      color: "",
      message: "",
    },
    messageDialog: {
      showing: false,
      type: "error",
      message: "",
    },
    menuSize: 56,
    drawer: false,
  }),

  computed: {
    contentHeight(){
      return this.$vuetify.breakpoint.height - this.menuSize;
    },
    menuItems(){
      return [
        // {title: 'Início',          link: {name: 'home'}},
        // {title: 'Área de Usuário', link: {name: (this.logged ? 'app':'login')}, exact: false},
      ]
    },
    logged(){
      return this.$store.state.logged
    },
  },

  watch: {
    async '$store.state.organization'(){
      try {
        const response = await this.$axios.get('/credits', this.$store.state.orgParams);
        const credits = response.data;
        if (
          credits.monthly_credits === 0 && credits.individual_credits === 0 &&
          ![
            'billing',
            'subscribe',
            'individual-purchase',
          ].includes(this.$route.name) &&
          this.$store.state.organization.permissions.includes('billing')
        ) {
          await this.$router.replace({name: 'billing'});
        } else if (
          !this.$store.state.organization.permissions.includes('billing')
        ) {
          await this.$router.replace({name: 'new-document'});
        }
      } catch (e){
        console.log(e);
      }
    },
  },

  methods: {
      showMessage(color, msg, sound = false){
          this.messageSnackbar.showing = false;
          this.$nextTick(() => {
              this.messageSnackbar.color = color;
              this.messageSnackbar.message = msg;
              this.messageSnackbar.showing = true;
              if (sound) {
                  try{
                      const audio = new Audio(require('@/assets/Windows Background.wav'));
                      // const audio = new Audio(require('@/assets/WXPerro.mp3'));
                      audio.play();
                  }catch (e) {
                      console.log('error playing sound');
                      console.log(e);
                  }
              }
          });
      },
    showDialog(type, msg){
      this.messageDialog.type = type
      this.messageDialog.message = msg
      this.messageDialog.showing = true
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
  },

  created(){
    Vue.prototype.$showMessage = this.showMessage;
    Vue.prototype.$showDialog = this.showDialog;
    Vue.prototype.$getCookie = this.getCookie;
  },

}
</script>

<style>
html, body {
  overflow-x: hidden !important;
}
</style>
