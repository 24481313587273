import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const isProduction = (process.env.NODE_ENV !== 'development');


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // component: () => import('@/components/LandingPage.vue'),
      ...(
        isProduction ? {} : {
          redirect: {name: 'login'},
        }
      ),
      beforeEnter() {
        if (isProduction){
          location.href = 'https://www.datacertify.com.br';
        }
      },
    },
    {
      path: '/entrar',
      name: 'login',
      component: () => import('@/components/login/Login.vue'),
    },
    {
      path: '/cadastro',
      name: 'pre-signup',
      component: () => import('@/components/login/PreSignup.vue'),
    },
    {
      path: '/completar-cadastro/:code',
      name: 'complete-signup',
      component: () => import('@/components/login/CompleteSignup.vue'),
    },
    {
      path: '/registro/:record_id',
      name: 'record',
      meta: { title: 'Registro', },
      component: () => import('@/components/records/PublicRecord.vue'),
    },
    {
      path: '/recuperar-senha/:code',
      name: 'recover-password',
      component: () => import('@/components/login/RecoverPassword.vue'),
    },
    {
      path: '/app/',
      name: 'app',
      redirect: {name: 'records'},
      component: () => import('@/components/InternalApp.vue'),
      children: [
        {
          path: '',
          redirect: {name: 'records'},
        },
        {
          path: 'novo-registro',
          name: 'new-document',
          meta: { title: 'Novo Registro', },
          component: () => import('@/components/records/new_record/NewRecord.vue'),
        },
        {
          path: 'registros',
          name: 'records',
          meta: { title: 'Registros', },
          component: () => import('@/components/records/Records.vue'),
        },
        {
          path: 'clientes',
          name: 'clients',
          meta: { title: 'Clientes', },
          component: () => import('@/components/account/Clients.vue'),
        },
        {
          path: 'usuarios',
          name: 'users',
          meta: { title: 'Usuários', },
          component: () => import('@/components/account/Users.vue'),
        },
        {
          path: 'pagamentos',
          name: 'billing',
          meta: { title: 'Assinatura e Pagamentos', },
          component: () => import('@/components/billing/BillingPage.vue'),
        },
        {
          path: 'assinar',
          name: 'subscribe',
          meta: { title: 'Assinar', },
          component: () => import('@/components/billing/Subscribe.vue'),
        },
        {
          path: 'comprar',
          name: 'individual-purchase',
          meta: { title: 'Compra de Registros Individuais', },
          component: () => import('@/components/billing/IndividualPurchase.vue'),
        },
        {
          path: 'conta',
          name: 'account',
          meta: { title: 'Minha Conta', },
          component: () => import('@/components/user/Account.vue'),
        },
        {
          path: 'suporte',
          name: 'support',
          meta: { title: 'Perguntas Frequentes', },
          component: () => import('@/components/account/Support.vue'),
        },
        {
          path: 'manual',
          name: 'trainings',
          meta: { title: 'Manual da Plataforma', },
          component: () => import('@/components/incomplete/Trainings.vue'),
        },
        {
          path: 'webinars',
          name: 'webinars',
          meta: { title: 'Webinars', },
          component: () => import('@/components/incomplete/Webinars.vue'),
        },
        {
          path: 'noticias',
          name: 'news',
          meta: { title: 'Notícias', },
          component: () => import('@/components/incomplete/News.vue'),
        },
        {
          path: 'feedback',
          name: 'feedback',
          meta: { title: 'Feedback', },
          component: () => import('@/components/incomplete/Feedback.vue'),
        },
        {
          path: 'compartilhe',
          name: 'share',
          meta: { title: 'Compartilhe', },
          component: () => import('@/components/incomplete/Share.vue'),
        },
      ],
    },
  ]
})
