import Vue from 'vue'
import Vuex from 'vuex'
import { getCookie, removeCookie } from 'tiny-cookie'

let token = getCookie('token')
let user = {}
let logged = false

if (token){
  user = JSON.parse(atob(token.split('.')[1]))
  logged = true
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged,
    user,
    organization: null,
    orgParams: {},
  },
  mutations: {
    setOrganization(state, organization){
      state.organization = organization;
      state.orgParams = {
        params: {
          organization_id: (organization || {}).id,
        },
      };
    },
    setUser(state, user){
      state.user = user
      state.logged = true
    },
    logout(state){
      state.user = {}
      state.logged = false
      removeCookie('token')
      removeCookie('token', {domain: '.datacertify.com.br'})
    },
  },
  actions: {}
})
